import { IonButton, IonButtons, IonCard, IonContent, IonIcon, IonInput, IonPage, withIonLifeCycle } from "@ionic/react";
import { computed, observable } from "mobx";
import { observer, Observer } from "mobx-react";
import React from "react";
import "./styles.less";
import { StdErr } from "../../../../lib/types/miscTypes";
import { ui } from "../../../../client/ui";
import { RouteComponentProps } from "react-router";
import { GroupSubRouteParams } from "../../../../lib/types/propTypes";
import CardedContainerHeader from "../../../../components/CardedContainerHeader";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { UIText } from "../../../../client/lang";
import { ShippingMode } from "../../lib/types/rateTypes";
import { addOutline, closeOutline } from "ionicons/icons";
import { asyncPause, getEventRealValue, preventDefaultStopProp, tableViewScrollToBottom } from "../../../../utils/helpers";
import { AutoObserverList } from "../../../../components/ObserverList";
import { ShippingModeListController } from "./controller";
import SortableTableHead from "../../../../components/SortableTableHead";
import { sortByProperty } from "../../lib/common";
import { ListController } from "../../../../lib/list-controller";
import Picker from "../../../../components/FormFields/Picker";
import Checkbox from "../../../../components/FormFields/Checkbox";

export interface ShippingModeListProps extends RouteComponentProps<GroupSubRouteParams<{}>> {}

@observer
class ShippingModeList extends React.Component<ShippingModeListProps> {
  url: string;
  title = UIText.rateManagement.systemShippingModes;

  controller: ShippingModeListController = {} as ShippingModeListController;
  listController: ListController<ShippingMode>;

  @observable loading: boolean = false;

  @computed get shippingModes(): ShippingMode[] {
    return this.controller.shippingModes;
  };
  @computed get sortedModes(): ShippingMode[] {
    return [...this.shippingModes].sort(
      (a, b) => sortByProperty(a, b, this.listController.sortBy, this.listController.sortDir)
    );
  };

  constructor(props) {
    super(props);
    this.url = props.match.url;
    this.controller = new ShippingModeListController();
    this.listController = new ListController({
      storeName: "ShippingModeListFilterSort",
      initialSortBy: "id"
    });
  }

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({
      err,
      actionName: actionName || this.title
    });

  ionViewWillEnter = () => {
    this.url = this.props.match.url;
    if (!this.controller.isSa) return this.props.history.replace("/");
    return this.onRefresh();
  };

  onRefresh = (event?: any) => {
    this.loading = true;
    return this.controller.loadAllData()
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleFieldChange = (event: any) => {
    const { name } = event.target;
    if (!name) return;
    const value = getEventRealValue(event, true);
    const id = name.split(".")[0];
    const field = name.split(".")[1];
    const changed = this.controller.onFieldChange(id, field, value);
    return !!changed && setTimeout(() => this.updateFieldAsync(id, field, value));
  };

  updateFieldAsync = async (id: number, field, value) => {
    this.loading = true;
    return this.controller.onUpdateField(id, field, value)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleAdd = async (event: any) => {
    preventDefaultStopProp(event);
    const mode: string = await new Promise(resolve => {
      ui.alert({
        header: "Add Shipping Mode",
        inputs: [{
          name: "modeCode",
          placeholder: "Mode code *",
        }],
        buttons: [
          {
            role: "cancel",
            text: UIText.generalCancel
          },
          {
            handler: result => resolve(result.modeCode),
            text: UIText.generalConfirm
          },
        ]
      })
    });
    if (!mode) return;
    this.loading = true;
    return this.controller.onAddShippingMode(mode)
    .then(() => asyncPause(500))
    .then(tableViewScrollToBottom)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleRemove = (event: any, mode: ShippingMode) => ui.reconfirm({
    header: `${UIText.generalDelete} Shipping Mode`,
    name: `the System Shipping Mode ID ${mode.id}${
      mode.localeMode[UIText.preference] ? ` "${mode.localeMode[UIText.preference]}"` : ""
    }`,
    verb: UIText.generalDelete.toLowerCase(),
    handler: () => this.handleRemoveConfirm(event, mode.id)
  });

  handleRemoveConfirm = (event: any, id: number) => {
    if (!id) return;
    this.loading = true;
    return this.controller.onRemoveShippingMode(id)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleColumnSort = (event: any, column: string) => {
    preventDefaultStopProp(event);
    this.listController.onSortByChange(column);
    this.listController.onSortDirChange();
  };

  render() {
    const { shippingRateBreakdowns } = this.controller;
    const { sortBy, sortDir } = this.listController;

    return (
      <IonPage className="headerOffset shippingModeList">
        <IonContent fullscreen className={`max ${ui.isMobile ? "" : "ion-padding"}`}>
          <IonCard className="flex max column container inboxContainer cardedContainer">
            <Observer>{() => (
              <CardedContainerHeader
                title={this.title}
                titleRight={
                  <IonButtons>
                    <IonButton color="primary" className="textNoTransform" onClick={this.handleAdd}>
                      <IonIcon slot="icon-only" icon={addOutline}/>
                    </IonButton>
                  </IonButtons>
                }
                backHandler={this.props.history.goBack}
                loading={this.loading}
                onRefresh={this.onRefresh}
              />
            )}</Observer>
            <IonContent className="max fullBackground ion-no-padding" scrollY={false}>
              <TableContainer component={props => <Paper {...props} square />} elevation={0}>
                <Table size="small" stickyHeader>
                  <Observer>{() => (
                    <SortableTableHead
                      columns={[
                        { name: "id", text: "ID *" },
                        { name: "mode", text: "Mode code *" },
                        { name: "localeMode", text: `Locale display (${UIText.preference})*` },
                        { name: "breakdownIds", text: "Rate Breakdowns *", noSort: true },
                        { name: "isAllowedAsComplexLeg", text: "Allowed as Breakdown Leg", noSort: true },
                        { name: "delete", text: UIText.generalDelete, noSort: true },
                      ]}
                      sortBy={sortBy}
                      sortDir={sortDir}
                      onSort={this.handleColumnSort}
                    />
                  )}</Observer>
                  <Observer>{() => (
                    <TableBody>
                      <AutoObserverList
                        list={this.sortedModes}
                        getItemKey={mode => mode.id}
                        render={(mode: ShippingMode) => {
                          return <TableRow>
                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                {mode.id}
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <IonInput
                                  className="ion-no-padding"
                                  name={`${mode.id}.mode`}
                                  value={mode.mode || ""}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <IonInput
                                  className="ion-no-padding"
                                  name={`${mode.id}.localeMode`}
                                  value={mode.localeMode[UIText.preference] || ""}
                                  onIonBlur={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="left" padding="none">
                                <Picker
                                  name={`${mode.id}.breakdownIds`}
                                  className="ion-no-padding"
                                  multiple
                                  placeholder="Rate Breakdowns"
                                  options={shippingRateBreakdowns.map(breakdown => ({
                                    name: breakdown.id.toString(), placeholder: breakdown.localeUnitName[UIText.preference]
                                  }))}
                                  value={mode.breakdownIds.map(id => id.toString())}
                                  onIonChange={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="center" padding="none" className="checkbox">
                                <Checkbox
                                  className="ion-no-padding"
                                  name={`${mode.id}.isAllowedAsComplexLeg`}
                                  checked={mode.isAllowedAsComplexLeg}
                                  onIonChange={this.handleFieldChange}
                                />
                              </TableCell>
                            )}</Observer>

                            <Observer>{() => (
                              <TableCell align="center" padding="none">
                                <IonButtons className="flex ion-align-items-center ion-justify-content-center">
                                  <IonButton color="danger" onClick={e => this.handleRemove(e, mode)}>
                                    <IonIcon slot="icon-only" icon={closeOutline}/>
                                  </IonButton>
                                </IonButtons>
                              </TableCell>
                            )}</Observer>
                          </TableRow>
                        }}
                      />
                    </TableBody>
                  )}</Observer>
                </Table>
              </TableContainer>
            </IonContent>
          </IonCard>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(ShippingModeList);