import { UIText } from "../../../../client/lang";
import React from "react";
import { observer, Observer } from "mobx-react";
import { GroupSubRouteParams } from "../../../../lib/types/propTypes";
import { RouteComponentProps } from "react-router";
import { computed, observable, when } from "mobx";
import { ShippingPlan, ShippingRate } from "../../lib/types/rateTypes";
import { StdErr } from "../../../../lib/types/miscTypes";
import { ui } from "../../../../client/ui";
import { getDisplayNameEng, isEmpty, isNonZeroFalse, preventDefaultStopProp, whenFulfill } from "../../../../utils/helpers";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonInput,
  IonLabel,
  IonLoading,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonText,
  withIonLifeCycle,
} from "@ionic/react";
import RatePortSelectionModal from "../../components/RatePortSelectionModal";
import RateChargeSelectionModal from "../../components/RateChargeSelectionModal";
import RateCarrierSelectionModal from "../../components/RateCarrierSelectionModal";
import CarrierChargeCustomizeModal from "../../components/CarrierChargeCustomizeModal";
import { ComplexShippingRateController } from "./controller";
import { Rfq } from "../../lib/types/dataTypes";
import { createOutline, trashOutline } from "ionicons/icons";
import CardedContainerHeader from "../../../../components/CardedContainerHeader";
import ComplexRateLeftColumn from "../../components/ComplexRateLeftColumn";
import ComplexRateLegsColumn from "../../components/ComplexRateLegsColumn";
import "./styles.less";
import ComplexRateLegSelectionModal from "../../components/ComplexRateLegSelectionModal";
import RateDataView from "../../components/RateDataView";

export interface ComplexShippingRateProps extends RouteComponentProps<GroupSubRouteParams<{
  id: string;
}>> {}

@observer
class ComplexShippingRate extends RateDataView<ComplexShippingRateProps, ComplexShippingRateController> {
  contentElm: HTMLIonContentElement;

  url: string;

  @observable showSegment: "left" | "legs" = "left";

  @observable currentRateId: number;

  descriptionField: HTMLIonInputElement;
  descriptionFieldRef = elm => this.descriptionField = elm;

  @observable editingDescription: boolean = false;

  @computed get id () {
    return this.controller.id;
  };
  @computed get title(): string {
    return this.plan.description || UIText.rateManagement.complexShippingRateTable;
  };
  @computed get plan(): ShippingPlan {
    return this.controller.plan || {} as ShippingPlan;
  };
  @computed get currentRate(): ShippingRate {
    return this.rates.find(rate => rate.id === this.currentRateId) || {} as ShippingRate;
  };


  @observable existingLegSelectionModalOrdinal: number;
  @computed get existingLegSelectionModalOpen(): boolean {
    return !isNonZeroFalse(this.existingLegSelectionModalOrdinal);
  };

  constructor(props) {
    super(props, new ComplexShippingRateController());
    this.url = props.match.url;
  }

  showError = (err: StdErr, kickOut?: boolean, actionName?: string) =>
    ui.showError({
      err,
      actionName,
      buttons: [{
        text: UIText.generalConfirm,
        handler: () => kickOut && this.handleBack()
      }]
    });

  ionViewWillEnter = () => {
    this.url = this.props.match.url;
    const { id, groupId } = this.props.match.params;
    if (isNaN(Number(groupId))) return this.handleBack();
    if (!id || isNaN(Number(id))) return this.handleBack();
    this.controller.setGroupId(Number(groupId));
    return this.onLoad(Number(id));
  };

  onLoad = (id: Rfq["id"]) => {
    this.loading = true;
    return this.controller.setId(id)
    .then(() => !isEmpty(this.rates) && this.handleRateSelect(null, this.rates[0].id))
    .catch((err: StdErr) => this.showError(err, true))
    .finally(() => this.loading = false);
  };

  setContentRef = elm => this.contentElm = elm;

  scrollToEnd = () => this.contentElm && this.contentElm.scrollToBottom(300);

  onSwitchSegment = (event: any) => {
    preventDefaultStopProp(event);
    const { value } = event.detail;
    return this.showSegment = value;
  };

  handleBack = (event?: any) => {
    preventDefaultStopProp(event);
    const urlFrags = this.url.split("/");
    urlFrags.pop();
    return this.props.history.push(urlFrags.join("/"));
  };


  handleRemove = (event: any) => {
    preventDefaultStopProp(event);
    return ui.reconfirm({
      header: `${UIText.generalDelete} ${UIText.rateManagement.shippingRate}`,
      name: this.title,
      verb: UIText.generalDelete.toLowerCase(),
      handler: this.handleRemoveConfirm
    });
  };

  handleRemoveConfirm = async () => {
    this.loading = this.heavyLoading = true;
    return this.controller.onRemoveShippingPlan(this.id)
    .then(this.handleBack)
    .catch(this.showError)
    .finally(() => this.loading = this.heavyLoading = this.loading = false);
  };

  handleEditDescription = async (event: any) => {
    preventDefaultStopProp(event);
    this.editingDescription = true;
    await whenFulfill(() => !!this.descriptionField);
    const input = await this.descriptionField.getInputElement();
    if (!input) return;
    input.addEventListener("focusout", () => this.editingDescription = false);
    return input && input.focus();
  };

  updatePlanLegFieldAsync = async (id: number, field, value) => {
    this.loading = true;
    return this.controller.onUpdatePlanLeg(this.id, id, field, value)
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handlePlanOrigClick = async (event: any) => {
    preventDefaultStopProp(event);
    await when(() => this.controller.ready);
    const rate = this.rates[0];
    if (!rate) return this.handleAddRate(null, 0).then(this.handlePlanOrigClick);
    this.handleRateSelect(event, rate.id);
    this.handlePortModalOpen(event, rate.id, "polPortId")
  };

  handlePlanDestClick = async (event: any) => {
    preventDefaultStopProp(event);
    await when(() => this.controller.ready);
    const rate = this.rates[this.rates.length - 1];
    if (!rate) return this.handleAddRate(null, 0).then(this.handlePlanDestClick);
    this.handleRateSelect(event, rate.id);
    this.handlePortModalOpen(event, rate.id, "podPortId")
  };


  handleRateSelect = (event: any, rateId: number) => {
    this.currentRateId = rateId;
    if (ui.isMobileIntermediate && event) this.showSegment = "legs";
  };

  handleAddRate = (event: any, ordinal: number) => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.onAddRate(this.id, ordinal)
    .then(rateId => this.handleRateSelect(true, rateId))
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleRemoveRate = (event: any, rate: ShippingRate) => {
    preventDefaultStopProp(event);
    return ui.reconfirm({
      header: `${UIText.generalDelete} ${UIText.rateManagement.leg}`,
      name: `${UIText.rateManagement.leg} ${rate.shippingPlanLeg.ordinal + 1}`,
      verb: UIText.generalDelete.toLowerCase(),
      handler: () => this.handleRemoveRateConfirm(event, rate)
    });
  };

  handleRemoveRateConfirm = (event: any, rate: ShippingRate) => {
    this.loading = true;
    return this.controller.onRemoveRate(rate.id)
    .then(() => ui.isMobileIntermediate && (this.showSegment = "left"))
    .catch(this.showError)
    .finally(() => this.loading = false);
  };


  handleLegSelectionModalOpen = (event: any, ordinal: number) => {
    preventDefaultStopProp(event);
    this.existingLegSelectionModalOrdinal = ordinal;
  };

  handleLegSelectionModalClose = () => this.existingLegSelectionModalOrdinal = undefined;

  handleLegSelect = async (rateId: number) => {
    this.loading = true;
    return this.controller.onAddRate(this.existingLegSelectionModalOrdinal, rateId)
    .then(rateId => this.handleRateSelect(true, rateId))
    .finally(() => this.loading = false);
  };


  render() {
    const {
      getSimplePlans,
      modes,
      uocs,
      isShippingChargeSelected,
      checkModeChangeLock,
      doesRateHaveBreakdownType,
      findModesForRate,
      findDisplayUnits,
      findAvailableShippingPorts,
      findAvailableShippingCharges,
      findAvailableCarriers,
      getCurrentCarrierCharges
    } = this.controller;

    const ContentChildren = <>
      <Observer>{() => (
        <ComplexRateLeftColumn
          plan={this.plan}
          selectedRateId={this.currentRateId}
          onPlanFieldChange={this.handlePlanFieldChange}
          onPolClick={this.handlePlanOrigClick}
          onPodClick={this.handlePlanDestClick}
          onRateClick={this.handleRateSelect}
          onAddRate={this.handleAddRate}
          onRemoveRate={this.handleRemoveRate}
          onLegSelectionModalOpen={this.handleLegSelectionModalOpen}
          updatePlanLegFieldAsync={this.updatePlanLegFieldAsync}
        />
      )}</Observer>

      <Observer>{() => (
        <ComplexRateLegsColumn
          plan={this.plan}
          rate={this.currentRate}
          modes={findModesForRate(this.plan)}
          uocs={uocs}
          findDisplayUnits={findDisplayUnits}
          checkModeChangeLock={checkModeChangeLock}
          doesRateHaveBreakdownType={doesRateHaveBreakdownType}
          handleRemove={e => this.handleRemoveRate(e, this.currentRate)}
          handleDetailFieldChange={e => this.handleRateFieldChange(e, this.currentRate)}
          handleAddRateUnit={(e, breakdownId) => this.handleAddRateUnit(e, this.currentRate, breakdownId)}
          handleRemoveRateUnit={(e, rateUnitId) => this.handleRemoveRateUnit(e, rateUnitId)}
          handleRateUnitFieldChange={(e, rateUnit) => this.handleRateUnitFieldChange(e, rateUnit)}
          handleChargeItemFieldChange={(e, rateChargeItem) => this.handleRateChargeItemFieldChange(e, rateChargeItem)}
          handlePortModalOpen={(e, property) => this.handlePortModalOpen(e, this.currentRate.id, property)}
          handleChargeModalOpen={e => this.handleChargeMenu(e, this.currentRate.id)}
          handleCarrierModalOpen={e => this.handleCarrierModalOpen(e, this.currentRate.id)}
        />
      )}</Observer>
    </>;

    return <IonPage className="headerOffset">
      <IonContent
        fullscreen
        ref={this.setContentRef}
        className={`max ${ui.isMobileIntermediate ? "" : "ion-padding"}`}
      >
        <Observer>{() => <IonLoading translucent isOpen={this.heavyLoading} />}</Observer>

        <IonCard className="flex max column container extra-wide complexShippingRate">
          <Observer>{() => (
            <CardedContainerHeader
              className="complexShippingRateHeader"
              title={
                <IonText
                  className="flex ion-align-items-center ion-activatable cardedContainerHeaderText"
                  style={{ flex: 1 }}
                  onClick={this.handleEditDescription}
                >
                  {this.editingDescription ? (
                    <IonInput
                      ref={this.descriptionFieldRef}
                      name="description"
                      color="primary"
                      className={`"ion-no-padding textPrimary" ${ui.isMobileIntermediate ? "font-m" : "font-l"}`}
                      placeholder={UIText.generalDescription}
                      value={this.plan.description}
                      onIonBlur={e => this.handlePlanFieldChange(e, this.plan)}
                    />
                  ) : <>
                    <IonText className={ui.isMobileIntermediate ? "font-m" : "font-l"}>
                      {this.title}
                    </IonText>
                    &nbsp;
                    <IonIcon icon={createOutline} className="font-m" />
                  </>}
                </IonText>
              }
              titleRight={<IonButtons>
                <IonButton onClick={this.handleRemove}>
                  <IonIcon icon={trashOutline} slot="icon-only" color="danger" />
                </IonButton>
              </IonButtons>}
              loading={this.loading}
              onRefresh={this.onRefresh}
              backHandler={this.handleBack}
            />
          )}</Observer>

          <Observer>{() => (
            <IonCardContent className="flex max column ion-no-padding">
              <IonContent scrollY={false} className="max fullBackground rfqDetailColumnsContainer">
                {ui.isMobileIntermediate ? (
                  <Observer>{() => (
                    <div className={`flex column rfqDetailLspSplit rfqDetailColumns ${this.showSegment}`}>
                      <IonSegment onIonChange={this.onSwitchSegment} value={this.showSegment}>
                        <IonSegmentButton value="left">
                          <IonLabel className="textBold textNoTransform">{UIText.rateManagement.rateDetails}</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="legs">
                          <IonLabel className="textBold textNoTransform">{UIText.rateManagement.leg}</IonLabel>
                        </IonSegmentButton>
                      </IonSegment>
                      {ContentChildren}
                    </div>
                  )}</Observer>
                ) : (
                  <div className="flex max ion-align-items-start ion-no-wrap rfqDetailColumns">
                    {ContentChildren}
                  </div>
                )}
              </IonContent>
            </IonCardContent>
          )}</Observer>
        </IonCard>

        <Observer>{() => (
          <RatePortSelectionModal
            modes={modes}
            portProperty={this.portModalPropertyName}
            ports={findAvailableShippingPorts(this.portModalRate)}
            rate={this.portModalRate}
            loading={this.loading}
            isOpen={this.portModalOpen}
            onDismiss={this.handlePortModalClose}
            onPortSelect={this.handlePortModalSelect}
            onModeFieldChange={!checkModeChangeLock(this.portModalRate) && (e => this.handleRateFieldChange(e, this.portModalRate))}
          />
        )}</Observer>

        <Observer>{() => (
          <RateChargeSelectionModal
            rate={this.chargeModalRate}
            carrierName={getDisplayNameEng(this.chargeModalCarrier.profile)}
            shippingCharges={findAvailableShippingCharges(this.chargeModalRate)}
            isOpen={this.chargeModalOpen}
            isAllChargesSelected={this.chargeModalAllChargesSelected}
            isShippingChargeSelected={isShippingChargeSelected}
            loading={this.loading}
            onDismiss={this.handleChargeModalClose}
            onCheckboxSelectAll={this.handleChargeModalCheckboxSelectAll}
            onCheckboxSelect={this.handleChargeModalCheckboxSelect}
            onChargeCustomizeModalOpen={this.handleChargeCustomizeModalOpen}
          />
        )}</Observer>

        <Observer>{() => (
          <RateCarrierSelectionModal
            carriers={findAvailableCarriers(this.plan, this.carrierModalRate)}
            modes={modes}
            rate={this.carrierModalRate}
            loading={this.loading}
            isOpen={this.carrierModalOpen}
            onDismiss={this.handleCarrierModalClose}
            onCarrierSelect={this.handleCarrierModalSelect}
          />
        )}</Observer>

        <Observer>{() => (
          <CarrierChargeCustomizeModal
            shippingCharges={findAvailableShippingCharges(this.chargeCustomizeModalRate)}
            carrier={this.chargeCustomizeModalCarrier}
            rate={this.chargeCustomizeModalRate}
            loading={this.loading}
            isOpen={this.chargeCustomizeModalOpen}
            onDismiss={this.handleChargeCustomizeModalClose}
            onRefresh={getCurrentCarrierCharges}
            onCreateCarrierOverride={this.handleCreateCarrierChargeOverride}
            onRemoveCarrierOverride={this.handleRemoveCarrierChargeOverride}
            onFieldChange={this.handleCarrierChargeFieldChange}
            onAddCustomCharge={this.handleCreateCarrierCustomCharge}
            onDuplicateCustomCharge={this.handleDuplicateCarrierCustomCharge}
          />
        )}</Observer>

        <Observer>{() => (
          <ComplexRateLegSelectionModal
            getSimplePlans={getSimplePlans}
            plan={this.plan}
            modes={modes}
            findDisplayUnits={findDisplayUnits}
            checkModeChangeLock={checkModeChangeLock}
            doesRateHaveBreakdownType={doesRateHaveBreakdownType}
            uocs={uocs}
            isOpen={this.existingLegSelectionModalOpen}
            onDismiss={this.handleLegSelectionModalClose}
            onRateSelect={this.handleLegSelect}
          />
        )}</Observer>
      </IonContent>
    </IonPage>
  }
}

export default withIonLifeCycle(ComplexShippingRate);