import { Rfq } from "./types/dataTypes";
import { ElementType } from "../../../lib/types/miscTypes";
import { getShippingPlanFilterProperties, rfqFilterProperties } from "../config/constants";
import { isEmpty } from "../../../utils/helpers";
import { ShippingMode, ShippingPlan, ShippingRateCharge } from "./types/rateTypes";

/**
 * Charges
 */
export const filterChargesOnly = (shippingRateCharges: ShippingRateCharge[]) =>
  (shippingRateCharges || []).filter(shippingRateCharge => !(shippingRateCharge.shippingCharge || {}).isSurcharge);


/**
 * Surcharges
 */
export const filterSurchargesOnly = (shippingRateCharges: ShippingRateCharge[]) =>
  (shippingRateCharges || []).filter(shippingRateCharge => !!(shippingRateCharge.shippingCharge || {}).isSurcharge);


/**
 * RFQ
 */
export const filterRfqBySelectedFilters = (
  rfq: Rfq, filters: ElementType<typeof rfqFilterProperties>[], isShipper: boolean
) => {
  if (isEmpty(filters)) return true;
  const matched = filters.map(filter => {
    if (filter === "hasQuotation") {
      return isShipper ? rfq.shipperQuotationCount > 0 : rfq.lspQuotationCount > 0;
    }
    if (filter === "hasSolution") {
      return rfq.solutionCount > 0;
    }
    if (filter === "isAwarded") {
      return isShipper ? !!rfq.isAwarded : !!rfq.hasAwarded;
    }
    if (filter === "air") {
      const modes = [rfq.mode, ...(rfq.altModes || [])];
      return modes.includes("air");
    }
    if (filter === "sea") {
      const modes = [rfq.mode, ...(rfq.altModes || [])];
      return modes.includes("sea");
    }
    if (filter === "rail") {
      const modes = [rfq.mode, ...(rfq.altModes || [])];
      return modes.includes("rail");
    }
    if (filter === "road") {
      const modes = [rfq.mode, ...(rfq.altModes || [])];
      return modes.includes("road");
    }
    if (filter === "portToPort") {
      return !isEmpty(rfq.portToPort);
    }
    if (filter === "doorToPort") {
      return !isEmpty(rfq.doorToPort);
    }
    if (filter === "portToDoor") {
      return !isEmpty(rfq.portToDoor);
    }
    if (filter === "doorToDoor") {
      return !isEmpty(rfq.doorToDoor);
    }
    if (filter === "packing") {
      return !isEmpty(rfq.packing);
    }
    if (filter === "storage") {
      return !isEmpty(rfq.storage);
    }
    if (filter === "inspection") {
      return !isEmpty(rfq.inspection);
    }
    return false;
  });
  return !isEmpty(matched.filter(Boolean));
};

export const filterShippingPlanBySelectedFilters = (
  shippingPlan: ShippingPlan,
  filters: ElementType<ReturnType<typeof getShippingPlanFilterProperties>>[],
  modes: ShippingMode[]
) => {
  if (isEmpty(filters)) return true;
  const matched = filters.map(filter => {
    if (filter === "simple") {
      return shippingPlan.type === "simple";
    }
    // if (filter === "simple") {
    //   return shippingPlan.type === "multimodal";
    // }
    if (filter === "complex") {
      return shippingPlan.type === "complex";
    }
    if (filter === "air") {
      if (isEmpty(shippingPlan.shippingRates)) return false;
      return shippingPlan.shippingRates.some(shippingRate => {
        const modeIds = [
          shippingRate.modeId,
          ...(shippingRate.shippingRateLegs || []).map(leg => leg.modeId)
        ];
        const mode = modes.find(m => m.mode === "air");
        return mode && modeIds.includes(mode.id);
      });
    }
    if (filter === "sea") {
      if (isEmpty(shippingPlan.shippingRates)) return false;
      return shippingPlan.shippingRates.some(shippingRate => {
        const modeIds = [
          shippingRate.modeId,
          ...(shippingRate.shippingRateLegs || []).map(leg => leg.modeId)
        ];
        const mode = modes.find(m => m.mode === "sea");
        return mode && modeIds.includes(mode.id);
      });
    }
    if (filter === "rail") {
      if (isEmpty(shippingPlan.shippingRates)) return false;
      return shippingPlan.shippingRates.some(shippingRate => {
        const modeIds = [
          shippingRate.modeId,
          ...(shippingRate.shippingRateLegs || []).map(leg => leg.modeId)
        ];
        const mode = modes.find(m => m.mode === "rail");
        return mode && modeIds.includes(mode.id);
      });
    }
    if (filter === "road") {
      if (isEmpty(shippingPlan.shippingRates)) return false;
      return shippingPlan.shippingRates.some(shippingRate => {
        const modeIds = [
          shippingRate.modeId,
          ...(shippingRate.shippingRateLegs || []).map(leg => leg.modeId)
        ];
        const mode = modes.find(m => m.mode === "road");
        return mode && modeIds.includes(mode.id);
      });
    }
    if (filter === "multimodal") {
      if (isEmpty(shippingPlan.shippingRates)) return false;
      return shippingPlan.shippingRates.some(shippingRate => {
        const modeIds = [
          shippingRate.modeId,
          ...(shippingRate.shippingRateLegs || []).map(leg => leg.modeId)
        ];
        const mode = modes.find(m => m.mode === "multimodal");
        return mode && modeIds.includes(mode.id);
      });
    }
    return false;
  });
  return !isEmpty(matched.filter(Boolean));
};