import React from "react";
import { Observer, observer } from "mobx-react";
import "./styles.less";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRippleEffect,
  IonText,
} from "@ionic/react";
import { addOutline, caretDownSharp, caretUpSharp, createOutline, removeOutline, trashOutline } from "ionicons/icons";
import MaterialDateTimeItem from "../../../../components/MaterialDateTimeItem";
import Picker from "../../../../components/FormFields/Picker";
import { UIText } from "../../../../client/lang";
import InjectIonLabelHelpTip from "../../../../components/InjectIonLabelHelpTip";
import { PickerOption } from "../../../../lib/types/formTypes";
import { kgPerUomId, modeContainerDependencyMap } from "../../config/constants";
import {
  ShippingMode,
  ShippingPlan,
  ShippingRate,
  ShippingRateBreakdown,
  ShippingRateChargeItem,
  ShippingRateUnit,
  ShippingUoc,
} from "../../lib/types/rateTypes";
import RateCardChargeItemTable from "../RateCardChargeItemTable";
import { ObserverList } from "../../../../components/ObserverList";
import { computed, observable } from "mobx";
import { getDisplayNameEng, isEmpty, preventDefaultStopProp, whenFulfill } from "../../../../utils/helpers";
import { PortSelectionPropertyNames } from "../../lib/types/propTypes";
import RateTransitPortsIndicator from "../RateTransitPortsIndicator";
import { filterChargesOnly, filterSurchargesOnly } from "../../lib/filters";
import { getChargeItemsSum, getContainerPrefixForBreakdown, getPortDisplayCode, getPortName } from "../../lib/common";
import { stateCtrl } from "../../../../client/state";
import Accordion from "../../../../components/Accordion";
import { AccordionController } from "../../../../lib/accordion";
import { Tooltip } from "@material-ui/core";
import RateBasicFreightItem from "../RateBasicFreightItem";
import { ui } from "../../../../client/ui";

export interface RateCardProps extends React.ComponentProps<typeof IonCard> {
  readonly?: boolean;
  plan: ShippingPlan;
  rate?: ShippingRate;
  modes: ShippingMode[];
  uocs: ShippingUoc[];
  description?: string;
  isSa?: boolean;
  editable?: boolean;
  findDisplayUnits: (rate: ShippingRate, rateUnit: ShippingRateUnit) => PickerOption[];
  checkModeChangeLock: (rate: ShippingRate) => boolean;
  doesRateHaveBreakdownType: (rate: ShippingRate, breakdown: ShippingRateBreakdown) => boolean;
  handleDetailFieldChange?: (event: any) => void;
  handleDescriptionChange?: (event: any) => void;
  handleRateUnitFieldChange?: (event: any, unit: ShippingRateUnit) => void;
  handleAddRateUnit?: (event: any, breakdownId: number) => void;
  handleRemoveRateUnit?: (event: any, id: number) => void;
  handleChargeItemFieldChange?: (event: any, rateChargeItem: ShippingRateChargeItem) => void;
  handlePortModalOpen?: (event: any, property: PortSelectionPropertyNames) => void;
  handleTransitPortModalOpen?: (event: any) => void;
  handleChargeModalOpen?: (event: any) => void;
  handleCarrierModalOpen?: (event: any) => void;
  handleRemove?: (event: any) => void;
  extraButtons?: React.ReactNode | React.ReactNode[];
}

@observer
class RateCard extends React.Component<RateCardProps> {
  descriptionField: HTMLIonInputElement;
  descriptionFieldRef = elm => this.descriptionField = elm;

  accordionController: AccordionController;

  @observable editingDescription: boolean = false;

  constructor(props) {
    super(props);
    this.accordionController = new AccordionController(`rateCard_${this.props.plan.id}`, false);
  }

  showModeLockedMessage = (event: any) => {
    preventDefaultStopProp(event);
    return ui.alert({
      header: UIText.rateManagement.mode,
      message: UIText.rateManagement.lockedModeChange,
      buttons: [UIText.generalConfirm]
    });
  };

  handleSectionCollapse = (event: any, section: string) => {
    preventDefaultStopProp(event);
    const isCollapsed = this.accordionController.isAccordionExpanded(section);
    this.accordionController.onAccordionFold(section, !isCollapsed);
  };

  handleEditDescription = async (event: any) => {
    preventDefaultStopProp(event);
    this.editingDescription = true;
    await whenFulfill(() => !!this.descriptionField);
    const input = await this.descriptionField.getInputElement();
    if (!input) return;
    input.addEventListener("focusout", () => this.editingDescription = false);
    return input && input.focus();
  };

  render() {
    const {
      className,
      readonly,
      plan,
      modes,
      uocs,
      isSa,
      findDisplayUnits,
      checkModeChangeLock,
      doesRateHaveBreakdownType,
      handleDetailFieldChange,
      handleDescriptionChange,
      handleAddRateUnit,
      handleRemoveRateUnit,
      handleRateUnitFieldChange,
      handleChargeItemFieldChange,
      handlePortModalOpen,
      handleTransitPortModalOpen,
      handleChargeModalOpen,
      handleCarrierModalOpen,
      handleRemove,
      extraButtons
    } = this.props;

    const { isAccordionExpanded } = this.accordionController;

    const rate = this.props.rate || (!isEmpty(plan.shippingRates) && plan.shippingRates[0]);
    const currentMode = modes.find(mode => mode.id === rate.modeId) || {} as ShippingMode;
    const description = this.props.description || plan.description;

    const unitsBreakdown = rate.shippingMode.shippingRateBreakdowns.find(breakdown => breakdown.unitType === "unit");
    const weightBreaksBreakdown = rate.shippingMode.shippingRateBreakdowns.find(breakdown => breakdown.unitType === "weightBreak");
    const wmBreakdown = rate.shippingMode.shippingRateBreakdowns.find(breakdown => breakdown.unitType === "w/m");
    const palletBreakdown = rate.shippingMode.shippingRateBreakdowns.find(breakdown => breakdown.unitType === "pallet");

    const isNotSolelyFclFtl = rate.container !== "fcl" && rate.container !== "ftl";

    const shouldShowUnits = doesRateHaveBreakdownType(rate, unitsBreakdown) && rate.container.match(/fcl|ftl/g);
    // const shouldShowWeightBreaks = doesRateHaveBreakdownType(rate, weightBreaksBreakdown) && rate.container === "pieces";
    // const shouldShowWM = doesRateHaveBreakdownType(rate, wmBreakdown) && (rate.container || "").match(/lcl|ltl/g);
    const shouldShowWeightBreaks = doesRateHaveBreakdownType(rate, weightBreaksBreakdown) && isNotSolelyFclFtl;
    const shouldShowWM = doesRateHaveBreakdownType(rate, wmBreakdown) && isNotSolelyFclFtl;
    const shouldShowPallet = doesRateHaveBreakdownType(rate, palletBreakdown) && isNotSolelyFclFtl;

    const isModeChangeLocked = checkModeChangeLock(rate);

    return <IonCard className={`rateCard ${className || ""}`} color="light">
      <Observer>{() => (
        <IonCardHeader className="flex ion-align-items-center ion-justify-content-between">
          <IonCardTitle color="primary" className="textPrimary textBold" style={{ flex: 1 }}>
            {!readonly && this.editingDescription ? (
              <IonInput
                ref={this.descriptionFieldRef}
                name="description"
                color="primary"
                className="textPrimary textBold"
                placeholder={UIText.generalDescription}
                value={description}
                onIonBlur={handleDescriptionChange}
              />
            ) : (
              <IonText
                className={`flex ion-align-items-center editDescription ${handleDescriptionChange ? "ion-activatable" : ""}`}
                onClick={handleDescriptionChange && this.handleEditDescription}
              >
                {description || (UIText.rateManagement.shippingRateTable)}&nbsp;
                {handleDescriptionChange && <IonIcon className="editDescriptionIcon" icon={createOutline} />}
              </IonText>
            )}
            {((isSa && plan.lspGroup && plan.lspGroup.id !== stateCtrl.currentGroupId) || readonly) && (
              <IonText className="font-xs textNormal lspName">
                {plan.lspGroup && <>{UIText.lsp}:&nbsp;{getDisplayNameEng((plan.lspGroup || {}).profile)}&nbsp;</>}
                {readonly && `(${UIText.generalReadonly})`}
              </IonText>
            )}
          </IonCardTitle>
          {!readonly && <IonButtons>
            <IonButton onClick={handleRemove}>
              <IonIcon icon={trashOutline} slot="icon-only" color="danger" />
            </IonButton>
          </IonButtons>}
          {extraButtons || null}
        </IonCardHeader>
      )}</Observer>


      <IonCardContent>
        <IonCardHeader>
          <IonCardSubtitle className="font-s textBold textDarkMedium">
            {UIText.rateManagement.portRouteSummary}
          </IonCardSubtitle>
        </IonCardHeader>
        <Observer>{() => {
          const polPort = currentMode.mode === "multimodal" ? plan.origPort : rate.polPort;
          const podPort = currentMode.mode === "multimodal" ? plan.destPort : rate.podPort;
          return <div className="flex column firstGroup itemGroups">
            <div className="flex ion-wrap ion-align-items-start">
              <div className="flex ion-wrap firstGroupSubWrap">
              <IonItem onClick={isModeChangeLocked && this.showModeLockedMessage}>
                  <IonLabel color="primary" position="floating">
                    {UIText.rateManagement.mode}
                  </IonLabel>
                  <Picker
                    disabled={readonly || isModeChangeLocked}
                    name="modeId"
                    options={modes.map(mode => ({
                      name: mode.id.toString(), placeholder: mode.localeMode[UIText.preference]
                    }))}
                    value={rate.modeId.toString()}
                    placeholder={UIText.rateManagement.mode}
                    onIonChange={handleDetailFieldChange}
                  />
                </IonItem>
                <IonItem
                  className="relative ion-activatable"
                  onClick={currentMode.mode === "multimodal"
                    ? handleTransitPortModalOpen
                    : (handlePortModalOpen && (e => handlePortModalOpen(e, "polPortId")))}
                >
                  <IonLabel color="primary" position="stacked">
                    {currentMode.mode === "air" ? UIText.rfqForm.origin : UIText.POL}
                  </IonLabel>
                  <Tooltip
                    arrow
                    enterDelay={isEmpty(polPort) ? Number.MAX_SAFE_INTEGER : undefined}
                    placement="bottom"
                    title={getPortName(polPort)}
                  >
                    <div className="linkButton">
                      <IonInput
                        readonly
                        className="noEvents textPrimary textUnderline"
                        value={getPortDisplayCode(polPort)}
                        placeholder={readonly ? "—" : UIText.rateManagement.selectShippingPort}
                      />
                    </div>
                  </Tooltip>
                </IonItem>
                <IonItem
                  className="relative ion-activatable"
                  onClick={currentMode.mode === "multimodal"
                    ? handleTransitPortModalOpen
                    : (handlePortModalOpen && (e => handlePortModalOpen(e, "podPortId")))}
                >
                  <IonLabel color="primary" position="stacked">
                    {currentMode.mode === "air" ? UIText.rfqForm.destination : UIText.POD}
                  </IonLabel>
                  <Tooltip
                    arrow
                    enterDelay={isEmpty(podPort) ? Number.MAX_SAFE_INTEGER : undefined}
                    placement="bottom"
                    title={getPortName(podPort)}
                  >
                    <div className="linkButton">
                      <IonInput
                        readonly
                        className="noEvents textPrimary textUnderline"
                        value={getPortDisplayCode(podPort)}
                        placeholder={readonly ? "—" : UIText.rateManagement.selectShippingPort}
                      />
                    </div>
                  </Tooltip>
                </IonItem>
              </div>
            </div>
          </div>;
        }}</Observer>

        <IonCardHeader>
          <IonCardSubtitle className="font-s textBold textDarkMedium">
            {UIText.rateManagement.rateDetails}
          </IonCardSubtitle>
        </IonCardHeader>
        <Observer>{() => (
          <div className="flex column firstGroup itemGroups">
            <div className="flex ion-wrap ion-align-items-start">
              <div className="flex ion-wrap firstGroupSubWrap">
                <IonItem>
                  <IonLabel color="primary" position="floating">
                    {UIText.rateManagement.containerType}
                  </IonLabel>
                  <Picker
                    name="container"
                    options={(modeContainerDependencyMap[currentMode.mode] || []).map(option => ({
                      name: option, placeholder: UIText.rateManagement[option]
                    }))}
                    value={rate.container}
                    disabled={readonly}
                    placeholder={UIText.generalUnavailable}
                    onIonChange={handleDetailFieldChange}
                  />
                  <InjectIonLabelHelpTip
                    className="helpTip absolute"
                    help={{
                      header: UIText.generalHelp,
                      message: UIText.generalHelp
                    }}
                  />
                </IonItem>
                <IonItem className="relative ion-activatable" onClick={handleCarrierModalOpen}>
                  <IonLabel color="primary" position="stacked">
                    {UIText.rateManagement.carrier}
                  </IonLabel>
                  <Tooltip
                    arrow
                    enterDelay={isEmpty(rate.carrierGroup) ? Number.MAX_SAFE_INTEGER : undefined}
                    placement="bottom"
                    title={getDisplayNameEng((rate.carrierGroup || {}).profile)}
                  >
                    <div className="linkButton">
                      <IonInput
                        readonly
                        className="noEvents textPrimary textUnderline"
                        value={(((rate.carrierGroup || {}).profile || {}).data || {}).abbreviation || ""}
                        placeholder={readonly ? "—" : UIText.rateManagement.selectACarrier}
                      />
                    </div>
                  </Tooltip>
                </IonItem>
                {!readonly && (
                  <IonItem className="relative ion-activatable" onClick={handleChargeModalOpen}>
                    <IonLabel color="primary" position="stacked">
                      {UIText.rateManagement.chargesSurcharges}
                    </IonLabel>
                    <div className="linkButton" onClick={handleChargeModalOpen}>
                      <IonInput
                        readonly
                        className="noEvents"
                        placeholder={UIText.rateManagement.manageChargesSurcharges}
                      />
                    </div>
                  </IonItem>
                )}
              </div>
            </div>
          </div>
        )}</Observer>

        {currentMode.mode === "multimodal" && <>
          <IonCardHeader>
            <IonCardSubtitle className="font-s textBold textDarkMedium">
              {UIText.rateManagement.transitPortRouteSummary}
            </IonCardSubtitle>
          </IonCardHeader>
          <Observer>{() => (
            <div className="flex column transitPortGroup itemGroups">
              <div className="flex ion-wrap ion-align-items-start">
                <IonItem lines="none" style={{ flex: 1 }}>
                  <IonLabel color="primary" position="stacked">
                    {UIText.rateManagement.transitPorts}
                  </IonLabel>
                  <div className="relative ion-activatable linkButton" onClick={handleTransitPortModalOpen}>
                    {!isEmpty(rate.shippingRateLegs && rate.shippingRateLegs.map(leg => leg.polPort || leg.podPort).filter(Boolean)) ? (
                      <div className="ion-padding-vertical">
                        <RateTransitPortsIndicator showTransshipment legs={rate.shippingRateLegs} />
                      </div>
                    ) : (
                      <IonInput
                        readonly
                        className="noEvents textPrimary textUnderline"
                        placeholder={UIText.rateManagement.transitPortsPlaceholder}
                      />
                    )}
                  </div>
                </IonItem>
              </div>
            </div>
          )}</Observer>
        </>}

        <Observer>{() => (
          <Accordion
            useDiv
            animated={false}
            className="ion-no-margin"
            headerComponent={
              <Observer>{() => (
                <IonCardHeader className="flex ion-justify-content-between ion-align-items-center relative ion-activatable">
                  <IonCardSubtitle className="font-s textBold textDarkMedium">
                    {UIText.rateManagement.charges}
                  </IonCardSubtitle>
                  <IonIcon
                    className="caret"
                    icon={isAccordionExpanded("charges") ? caretUpSharp : caretDownSharp}
                    color="primary"
                  />
                  <IonRippleEffect />
                </IonCardHeader>
              )}</Observer>
            }
            expanded={isAccordionExpanded("charges")}
            onClick={e => this.handleSectionCollapse(e, "charges")}
          >
            <Observer>{() => (
              <div className="flex column secondGroup">
                <RateCardChargeItemTable
                  readonly={readonly}
                  isChargesOnly
                  parentEntity={rate}
                  uocs={uocs}
                  shippingRateCharges={filterChargesOnly(rate.shippingRateCharges)}
                  handleParentEntityFieldChange={handleDetailFieldChange}
                  handleChargeItemFieldChange={handleChargeItemFieldChange}
                />
              </div>
            )}</Observer>
          </Accordion>
        )}</Observer>

        <Observer>{() => <>
          {shouldShowUnits && (
            <UnitsSectionAccordion
              breakdown={unitsBreakdown}
              readonly={readonly}
              rate={rate}
              uocs={uocs}
              findDisplayUnits={findDisplayUnits}
              accordionController={this.accordionController}
              handleRateUnitFieldChange={handleRateUnitFieldChange}
              handleChargeItemFieldChange={handleChargeItemFieldChange}
              handleAddRateUnit={handleAddRateUnit}
              handleRemoveRateUnit={handleRemoveRateUnit}
            />
          )}
        </>}</Observer>

        <Observer>{() => <>
          {shouldShowWeightBreaks && (
            <UnitsSectionAccordion
              breakdown={weightBreaksBreakdown}
              readonly={readonly}
              rate={rate}
              uocs={uocs}
              findDisplayUnits={findDisplayUnits}
              accordionController={this.accordionController}
              handleRateUnitFieldChange={handleRateUnitFieldChange}
              handleChargeItemFieldChange={handleChargeItemFieldChange}
              handleAddRateUnit={handleAddRateUnit}
              handleRemoveRateUnit={handleRemoveRateUnit}
            />
          )}
        </>}</Observer>

        <Observer>{() => <>
          {shouldShowWM && (
            <UnitsSectionAccordion
              breakdown={wmBreakdown}
              readonly={readonly}
              rate={rate}
              uocs={uocs}
              findDisplayUnits={findDisplayUnits}
              accordionController={this.accordionController}
              handleRateUnitFieldChange={handleRateUnitFieldChange}
              handleChargeItemFieldChange={handleChargeItemFieldChange}
              handleAddRateUnit={handleAddRateUnit}
              handleRemoveRateUnit={handleRemoveRateUnit}
            />
          )}
        </>}</Observer>

        <Observer>{() => <>
          {shouldShowPallet && (
            <UnitsSectionAccordion
              breakdown={palletBreakdown}
              readonly={readonly}
              rate={rate}
              uocs={uocs}
              findDisplayUnits={findDisplayUnits}
              accordionController={this.accordionController}
              handleRateUnitFieldChange={handleRateUnitFieldChange}
              handleChargeItemFieldChange={handleChargeItemFieldChange}
              handleAddRateUnit={handleAddRateUnit}
              handleRemoveRateUnit={handleRemoveRateUnit}
            />
          )}
        </>}</Observer>
      </IonCardContent>
    </IonCard>;
  }
}

interface UnitsSectionAccordionProps extends Partial<RateCardProps> {
  breakdown: ShippingRateBreakdown;
  uocs: ShippingUoc[];
  accordionController: AccordionController;
}

const UnitsSectionAccordion: React.FC<UnitsSectionAccordionProps> = observer(
  ({
     readonly,
     breakdown,
     rate,
     uocs,
     findDisplayUnits,
     accordionController,
     handleRateUnitFieldChange,
     handleChargeItemFieldChange,
     handleAddRateUnit,
     handleRemoveRateUnit
   }) => {
    const { isAccordionExpanded } = accordionController;
    const handleSectionCollapse = (event: any, section: string) => {
      preventDefaultStopProp(event);
      const isCollapsed = accordionController.isAccordionExpanded(section);
      accordionController.onAccordionFold(section, !isCollapsed);
    };
    const shippingRateUnits = (rate.shippingRateUnits || []).filter(shippingRateUnit => (
      ((shippingRateUnit.shippingUnit || {}).shippingRateBreakdown || {}).unitType === breakdown.unitType
    ));
    return <>
      <Observer>{() => (
        <Accordion
          useDiv
          animated={false}
          className="ion-no-margin"
          headerComponent={
            <Observer>{() => (
              <IonCardHeader className="flex ion-justify-content-between ion-align-items-center relative ion-activatable">
                <IonCardSubtitle className="font-s textBold textDarkMedium">
                  {getContainerPrefixForBreakdown(rate, breakdown)}{(breakdown.localeUnitNamePlural || {})[UIText.preference]}
                </IonCardSubtitle>
                <IonIcon
                  className="caret"
                  icon={isAccordionExpanded(breakdown.unitType) ? caretUpSharp : caretDownSharp}
                  color="primary"
                />
                <IonRippleEffect />
              </IonCardHeader>
            )}</Observer>
          }
          expanded={isAccordionExpanded(breakdown.unitType)}
          onClick={e => handleSectionCollapse(e, breakdown.unitType)}
        >
          <Observer>{() => (
            <div className="flex column thirdGroup">
              <ObserverList
                list={shippingRateUnits}
                render={(unit: ShippingRateUnit) => <Observer key={unit.id}>{() => {
                  const shippingUnits = findDisplayUnits(rate, unit);
                  return <RateUnitSection
                    unit={unit}
                    rate={rate}
                    breakdown={breakdown}
                    readonly={readonly}
                    shippingUnits={shippingUnits}
                    uocs={uocs}
                    handleRateUnitFieldChange={handleRateUnitFieldChange}
                    handleChargeItemFieldChange={handleChargeItemFieldChange}
                    handleRemoveRateUnit={handleRemoveRateUnit}
                  />;
                }}</Observer>}
              />
            </div>
          )}</Observer>
        </Accordion>
      )}</Observer>

      <Observer>{() => <>
        {!readonly && isAccordionExpanded(breakdown.unitType) && (
          <IonButtons className="ion-justify-content-center ion-margin-top">
            <IonButton color="primary" className="textNoTransform" onClick={e => handleAddRateUnit(e, breakdown.id)}>
              <IonIcon slot="start" icon={addOutline}/>{UIText.generalAdd}&nbsp;{(breakdown.localeUnitName || {})[UIText.preference]}
            </IonButton>
          </IonButtons>
        )}
      </>}</Observer>
    </>;
  });

interface RateUnitSectionProps extends Partial<UnitsSectionAccordionProps> {
  unit: ShippingRateUnit;
  shippingUnits: PickerOption[];
}

@observer
class RateUnitSection extends React.Component<RateUnitSectionProps> {
  @computed get availableUocs(): ShippingUoc[] {
    return this.props.breakdown.unitType === "w/m"
      ? this.props.uocs.filter(uoc => (uoc.perUom || {}).id === kgPerUomId)
      : this.props.uocs;
  };
  @computed get basicAmountPerUoc(): ShippingUoc {
    const { uocs, unit } = this.props;
    return uocs.find(uoc => uoc.id === unit.basicAmountPerUocId) || {} as ShippingUoc;
  };

  render() {
    const {
      unit,
      rate,
      breakdown,
      readonly,
      shippingUnits,
      uocs,
      handleRateUnitFieldChange,
      handleChargeItemFieldChange,
      handleRemoveRateUnit
    } = this.props;

    return <div className="flex column rateUnitItem">
      <Observer>{() => (
        <div className="flex ion-wrap ion-align-items-center rateUnitInfo">
          <div className="flex ion-wrap thirdGroupSubWrap">
            <IonItem className="relative" lines="full">
              <IonLabel color="primary" position="floating">
                {UIText.rateManagement.unit}
              </IonLabel>
              <Picker
                disabled={readonly}
                className="textBold"
                name="unitId"
                options={shippingUnits}
                value={unit.unitId.toString()}
                placeholder={UIText.generalUnavailable}
                onIonChange={!readonly && (e => handleRateUnitFieldChange(e, unit))}
              />
            </IonItem>
            <RateBasicFreightItem
              readonly={readonly}
              unit={unit}
              breakdown={breakdown}
              uocs={uocs}
              handleFieldChange={!readonly && (e => handleRateUnitFieldChange(e, unit))}
            />
          </div>
          <div className="flex thirdGroupSubWrap inverseWrapMobile400">
            <IonItem className="relative" lines="full">
              <IonLabel color="primary" position="floating">
                {UIText.rateManagement.basicFreightPer}
              </IonLabel>
              <Picker
                disabled={readonly || breakdown.unitType === "unit"}
                name="basicAmountPerUocId"
                options={this.availableUocs.map(uoc => ({
                  name: uoc.id.toString(),
                  placeholder: (uoc.localeUoc || {})[UIText.preference] || uoc.id.toString()
                }))}
                value={(this.basicAmountPerUoc.id || "").toString()}
                placeholder={isEmpty(this.availableUocs) ? UIText.generalUnavailable : "—"}
                onIonChange={(!readonly && breakdown.unitType !== "unit") && (e => handleRateUnitFieldChange(e, unit))}
              />
              <InjectIonLabelHelpTip
                className="helpTip absolute"
                help={{
                  header: UIText.generalHelp,
                  message: UIText.generalHelp
                }}
              />
            </IonItem>
            <MaterialDateTimeItem
              readonly={readonly}
              className="relative"
              useMuiOnly
              // type="datetime"
              name="validityEndDate"
              lines="full"
              label={UIText.rateManagement.validity}
              color="primary"
              min={new Date().toISOString()}
              placeholder={UIText.rateManagement.validity}
              displayFormat="YYYY-MM-DD"
              value={new Date(unit.validityEndDate).toISOString()}
              onIonChange={!readonly && (e => handleRateUnitFieldChange(e, unit))}
            >
              <InjectIonLabelHelpTip
                className="helpTip absolute"
                help={{
                  header: UIText.generalHelp,
                  message: UIText.generalHelp
                }}
              />
            </MaterialDateTimeItem>
          </div>
          <div className="flex ion-wrap thirdGroupSubWrap">
            <IonItem className="relative" lines="full">
              <IonLabel color="primary" position="floating">
                {UIText.rateManagement.ETD}
              </IonLabel>
              <Picker
                disabled={readonly}
                name="estimatedDepartureDay"
                options={UIText.rateManagement.dayEtd.map((d, i) => ({
                  name: i.toString(), placeholder: d
                }))}
                value={unit.estimatedDepartureDay.toString()}
                onIonChange={!readonly && (e => handleRateUnitFieldChange(e, unit))}
              />
              <InjectIonLabelHelpTip
                className="helpTip absolute"
                help={{
                  header: UIText.generalHelp,
                  message: UIText.generalHelp
                }}
              />
            </IonItem>
            <IonItem className="relative" lines="full">
              <IonLabel color="primary" position="floating">
                {UIText.rateManagement.transitTime}
              </IonLabel>
              <IonInput
                readonly={readonly}
                name="transitTime"
                placeholder={UIText.rateManagement.transitTime}
                value={unit.transitTime}
                onIonBlur={!readonly && (e => handleRateUnitFieldChange(e, unit))}
              />
              <InjectIonLabelHelpTip
                className="helpTip absolute"
                help={{
                  header: UIText.generalHelp,
                  message: UIText.generalHelp
                }}
              />
            </IonItem>
          </div>
        </div>
      )}</Observer>

      <Observer>{() => (
        <RateCardChargeItemTable
          readonly={readonly}
          parentEntity={unit}
          uocs={uocs}
          shippingRateCharges={filterSurchargesOnly(rate.shippingRateCharges)}
          chargesAmount={getChargeItemsSum(rate)}
          handleParentEntityFieldChange={!readonly && handleRateUnitFieldChange}
          handleChargeItemFieldChange={!readonly && handleChargeItemFieldChange}
        />
      )}</Observer>

      <Observer>{() => <>
        {!readonly && (
          <IonButtons className="footerBar ion-justify-content-end">
            <IonButton color="danger" className="textNoTransform" onClick={e => handleRemoveRateUnit(e, unit.id)}>
              <IonIcon slot="start" icon={removeOutline} />{UIText.generalRemove}&nbsp;{(breakdown.localeUnitName || {})[UIText.preference]}
            </IonButton>
          </IonButtons>
        )}
      </>}</Observer>
    </div>;
  }
}

export default RateCard;
