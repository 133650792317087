import { ElementType, JSONString } from "../../../../lib/types/miscTypes";
import { dimensionOptions, roadFreightContainers, seaFreightContainers, volumeCBMOptions, weightOptions } from "../../config/constants";
import { PlacesAutocompleteResult } from "../../../../lib/types/dataTypes";

export interface RfqFormItemDTO {
  key: number;
  type: "pieces" | // Common
    "fcl" | "lcl" | // Sea or Rail
    "ftl" | "ltl"; // Road

  // Common
  quantity?: number;
  weight?: number;
  weightDispUnit?: ElementType<typeof weightOptions>;
  notes?: string;

  // FCL / FTL
  containerType?:
    ElementType<typeof seaFreightContainers> &
    ElementType<typeof roadFreightContainers>;
  // FCL
  isSOC?: boolean;

  // Pieces / LCL / LTL
  length?: number;
  width?: number;
  height?: number;
  dimensionDispUnit?: ElementType<typeof dimensionOptions>;
  volume?: number;
  volumeDispUnit?: ElementType<typeof volumeCBMOptions>;
  measurementMode?: "volume" | "dimension";
}

export interface RfqFormInlandServiceDTO {
  pickUpAddress?: PlacesAutocompleteResult;
  deliveryAddress?: PlacesAutocompleteResult;
  customsInformation?: string;
}

export interface RfqFormAdditionalServiceDTO {
  location?: PlacesAutocompleteResult;
  notes?: string;
  duration?: number;
  date?: number | Date;
}

export interface RfqFormPackingServiceDTO extends RfqFormAdditionalServiceDTO {
  locationOverride?: boolean;
}

export interface RfqFormInspectionServiceDTO {
  fumigation?: boolean;
  phytosanitary?: boolean;
  qualityTest?: boolean;
  _otherChecked?: boolean;
  other?: string;
}

export interface RfqFormDTO {
  mode: "air" | "sea" | "rail" | "road";
  altModes: RfqFormDTO["mode"][];
  multimodal: boolean;

  // Describe your Cargo
  goods: string;
  dangerous: boolean;
  perishable: boolean;
  liveAnimals: boolean;
  highValueMerchandise: boolean;
  other: boolean;

  // imoClassification: string;
  // unNumber: string;
  cargoReadyDate: Date | number;
  cargoArrivalDate?: Date | number;
  preferredDet?: number;
  otherInformation: string;
  otherAttributeNotes?: string;
  reference?: string;

  pol: PlacesAutocompleteResult;
  pod: PlacesAutocompleteResult;

  portToPort?: boolean;
  doorToPort?: Omit<RfqFormInlandServiceDTO, "deliveryAddress">;
  portToDoor?: Omit<RfqFormInlandServiceDTO, "pickUpAddress">;
  doorToDoor?: RfqFormInlandServiceDTO;
  packing?: RfqFormPackingServiceDTO;
  storage?: RfqFormAdditionalServiceDTO;
  inspection?: RfqFormInspectionServiceDTO;

  items?: JSONString;

  // To preserve local state for each different modes while switching.
  _air_items?: RfqFormItemDTO[];
  _sea_items?: RfqFormItemDTO[];
  _rail_items?: RfqFormItemDTO[];
  _road_items?: RfqFormItemDTO[];
}

export class RfqFormDTO implements RfqFormDTO {
  mode: RfqFormDTO["mode"] = "air";
  multimodal: RfqFormDTO["multimodal"] = false;
  portToPort?: RfqFormDTO["portToPort"] = true;
  otherInformation: RfqFormDTO["otherInformation"] = "";
  otherAttributeNotes?: RfqFormDTO["otherAttributeNotes"] = "";
  reference?: RfqFormDTO["reference"] = "";
  preferredDet?: RfqFormDTO["preferredDet"] = 0;
  goods: RfqFormDTO["goods"] = "";
  dangerous: RfqFormDTO["dangerous"] = false;
  perishable: RfqFormDTO["perishable"] = false;
  liveAnimals: RfqFormDTO["liveAnimals"] = false;
  highValueMerchandise: RfqFormDTO["highValueMerchandise"] = false;
  other: RfqFormDTO["other"] = false;
}