// export const weightOptions = ["g", "kg", "mt", "oz", "lb"] as const;
// export const weightOptions = ["kg", "mt"] as const;

// export const weightOptions = ["kg", "lb"] as const;

import { Carrier, CarrierProfile, ShippingPlan, ShippingPort, ShippingRate } from "../lib/types/rateTypes";

export const weightOptions = ["kg"] as const;
// export const dimensionOptions = ["cm", "m", "in", "ft"] as const;
export const dimensionOptions = ["cm", "m"] as const;

export const defaultWeightUnit = "kg";
export const defaultDimensionUnit = "cm";

export const seaFreightContainers = [
  "20'",
  "40'",
  "40'HC",
  "45'",
  "RF",
  "FR",
  "OT",
  "GOH"
];

export const roadFreightContainers = [
  "48'",
  "53'"
];

export const volumeCBMOptions = ["CBM = 1000 kg", "CBM = 363 kg"];

export const rfqSortProperties = [
  "submissionDate",
  "cargoReadyDate",
  "cargoArrivalDate",
  "quotationCount",
  "solutionCount",
  "itemCount"
];

export const rfqFilterProperties = [
  "hasQuotation",
  "hasSolution",
  "isAwarded",
  "",
  "air",
  "sea",
  "rail",
  "road",
  "",
  "portToPort",
  "doorToPort",
  "portToDoor",
  "doorToDoor",
  "",
  "packing",
  "storage",
  "inspection",
];

export const shippingPlanSortProperties: (keyof ShippingPlan)[] = [
  "description",
  "createTime",
  "updateTime"
];

export const getShippingPlanFilterProperties = (isRateList?: boolean) => [
  isRateList && "simple",
  isRateList && "complex",
  isRateList && " ",
  "air",
  "sea",
  "rail",
  "road",
  "multimodal"
].filter(Boolean);

export const carrierSortProperties: (keyof (CarrierProfile & Carrier))[] = [
  "id",
  "displayName",
  "tradeName",
  "abbreviation",
  "country",
  "type"
];

export const shippingPortSortProperties: (keyof ShippingPort)[] = [
  "iso3166A2Code",
  "iso3166A3Code",
  "portCode",
  "portName",
  "lat",
  "lng"
];

export const modeContainerDependencyMap: { [key: string]: ShippingRate["container"][] } = {
  air: ["pieces"],
  sea: ["fcl", "lcl", "fcl&lcl"],
  rail: ["fcl", "lcl", "fcl&lcl"],
  road: ["ftl", "ltl", "ftl&ltl"],
  multimodal: ["pieces", "fcl", "lcl", "ftl", "ltl", "fcl&lcl", "ftl&ltl"]
};

export enum EtdDayIds {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Daily
}

export const containerNameBreakdownMap = {
  unit: ["fcl", "ftl"],
  weightBreak: ["lcl", "ltl"],
  "w/m": ["lcl", "ltl"],
  pallet: ["lcl", "ltl"],
};

export const kgPerUomId = 1;