import { IonButton, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonReorder, IonRippleEffect, IonText } from "@ionic/react";
import { UIText } from "../../../../../client/lang";
import React, { MouseEvent } from "react";
import { observer } from "mobx-react";
import { RfqFormDTO, RfqFormItemDTO } from "../../../lib/types/rfqFormTypes";
import { caretDownSharp, closeSharp, ellipsisHorizontal } from "ionicons/icons";
import { defaultDimensionUnit, defaultWeightUnit, dimensionOptions, weightOptions } from "../../../config/constants";
import { preventDefaultStopProp } from "../../../../../utils/helpers";
import { ui } from "../../../../../client/ui";
import { ElementType } from "../../../../../lib/types/miscTypes";
import { observable } from "mobx";
import "./styles.less";
import { CheckboxChangeEventDetail } from "@ionic/core";
import MdIcon from "../../../../../components/MdIcon";
import { mdiDragVertical } from "@mdi/js";
import { getIonStyleVal } from "../../../../../config/styles/style-utils";
import { IonCheckboxWithReadonly } from "../../../../../components/FormFields/Checkbox";
import { getFreightItemTypes, rfqRoundDimension, rfqRoundWeight } from "../../../lib/common";
import TwoStageInput from "../../../../../components/TwoStageInput";
import HelpTip from "../../../../../components/HelpTip";
import { Form } from "../../../../../client/form";
import { ShippingUnit } from "../../../lib/types/rateTypes";

export interface RfqFormFreightItemProps {
  readonly?: boolean;
  error: { [key: string]: boolean };
  mode: RfqFormDTO["mode"];
  item: RfqFormItemDTO;
  index: number;
  shippingUnits: ShippingUnit[];
  onRemoveItem?: (event: MouseEvent) => void;
  onTypeChange?: (type: RfqFormItemDTO["type"]) => void;
  onNotesChange?: (event: CustomEvent) => void;
  onPiecesChange?: (event: CustomEvent) => void;
  onWeightChange?: (event: CustomEvent) => void;
  onUnitChange?: (
    unit: ElementType<typeof weightOptions> | ElementType<typeof dimensionOptions>,
    type: "weight" | "dimension",
    applyAll?: boolean
  ) => void;
  onContainerChange?: (event: CustomEvent) => void;
  onDimensionChange?: (event: CustomEvent) => void;
  onVolumeChange?: (event: CustomEvent) => void;
  onMeasurementModeChange?: (mode: RfqFormItemDTO["measurementMode"]) => void;
  onVolumeUnitChange?: (volume: RfqFormItemDTO["volumeDispUnit"]) => void;
  onSocChange?: (event: CustomEvent<CheckboxChangeEventDetail>) => void;
  // onAddInfoChange?: (event: CustomEvent) => void;
}

const UnitMenu = observer(({ options, isAll, isAllHandler, handler }) => (
  <IonList className="ion-no-padding">
    {/*Apply all box*/}
    {/*<IonItem lines="full">*/}
    {/*  <IonLabel>{UIText.applyToAll}</IonLabel>*/}
    {/*  <IonCheckbox checked={isAll} onIonChange={isAllHandler} />*/}
    {/*</IonItem>*/}
    {options.map(option => (
      <IonItem
        key={option}
        button
        lines="full"
        onClick={event => {
          ui.dismissPopover();
          return handler(option);
        }}
      >
        {option}
      </IonItem>
    ))}
  </IonList>
));

const NotesLet = observer(
  ({
     className,
     hasError,
     readonly,
     value,
     isDangerous,
     onChange,
     onDangerousChange
   }) => (
    <div className="notes">
      <IonItem>
        <IonLabel position="floating" color={hasError && "danger"}>
          {UIText.rfqForm.notes}
        </IonLabel>
        <TwoStageInput
          name="notes"
          endIcon={ellipsisHorizontal}
          readonly={readonly}
          placeholder={UIText.rfqForm.notes}
          value={value}
          onIonChange={onChange}
        />
      </IonItem>
    </div>
  ));

const PiecesLet = observer(({ className, hasError, readonly, type, pieces, onPiecesChange }) => (
  <IonItem className={`piecesLet ${className || ""}`} lines="full">
    <IonLabel position="floating" color={hasError && "danger"}>
      {Form.mandatoryFieldLabel(
        type === "fcl"
          ? UIText.rfqForm.numberOfContainers
          : type === "ftl"
          ? UIText.rfqForm.numberOfTrucks
          : UIText.rfqForm.numberOfPieces
      )}
    </IonLabel>
    <IonInput
      readonly={readonly}
      name="quantity"
      inputMode="numeric"
      type="number"
      placeholder="0"
      min="0"
      max="99"
      value={pieces}
      onIonChange={onPiecesChange}
    />
  </IonItem>
));

const ContainerLet = observer(
({
   className,
   hasError,
   readonly,
   type,
   value,
   units,
   onChange
 }) => {
  const containerUnits: ShippingUnit[] = units.filter((unit: ShippingUnit) => (unit.shippingRateBreakdown || {}).unitType === "unit");
  const currentUnit: ShippingUnit = units.find((unit: ShippingUnit) => !!value && (unit.id === Number(value)));
  const showDropdown = (event: any) => {
    preventDefaultStopProp(event);
    if (readonly) return;
    return ui.popoverMenu({
      event,
      // menuItems: (type === "fcl" ? seaFreightContainers : roadFreightContainers).map(text => ({
      //   text,
      //   handler: () => onChange(text)
      // }))
      menuItems: containerUnits.map(unit => ({
        key: unit.id,
        text: unit.unitName[UIText.preference],
        handler: () => onChange(unit.id)
      }))
    });
  };
  return <IonItem className={`containerLet ion-activatable ${className || ""}`} lines="full" onClick={showDropdown}>
    <div className="flex ion-padding-start ion-align-items-center">
      <div className="flex column">
        <IonLabel position="floating" className="flex ion-align-items-center" color={hasError && "danger"}>
          {Form.mandatoryFieldLabel(UIText.rfqForm.containerType)}
        </IonLabel>
        <IonInput
          readonly
          className="noEvents"
          value={((currentUnit || {}).unitName || {})[UIText.preference]}
        />
      </div>
      <IonIcon className="ion-margin-end" icon={caretDownSharp} color="dark"/>
    </div>
    <IonRippleEffect />
  </IonItem>;
});

const WeightLet = observer(({ className, hasError, readonly, weight, unit, perPiece, onUnitChange, onWeightChange }) => {
  const onWeightSelect = (event: any) => {
    preventDefaultStopProp(event);
    const isAll = observable({ value: false });
    ui.popover({
      event,
      component: () => <UnitMenu
        // options={weightOptions.map(uom => uom === "mt" ? "ton" : uom)}
        options={weightOptions}
        isAll={isAll.value}
        handler={o => onUnitChange(o === "ton" ? "mt" : o, "weight", isAll.value)}
        isAllHandler={() => isAll.value = !isAll.value}
      />
    });
  };
  return <IonItem className={`weightLet ${className || ""}`} lines="full">
    <IonLabel
      position="floating"
      className="flex weightLetLabel ion-align-items-center ion-activatable"
      color={hasError && "danger"}
      onClick={onWeightSelect}
    >
      {Form.mandatoryFieldLabel(`${UIText.rfqForm.weight} ${perPiece ? UIText.rfqForm.perPiece : ""} (${unit === "mt" ? "ton" : unit || defaultWeightUnit})`)}&nbsp;
      <IonIcon icon={caretDownSharp} color="dark"/>
    </IonLabel>
    <IonInput
      readonly={readonly}
      name="weight"
      inputMode="numeric"
      type="number"
      placeholder="0"
      min="0"
      value={rfqRoundWeight(weight, defaultWeightUnit, unit)}
      // onIonChange={onWeightChange}
      onIonBlur={onWeightChange}
    />
  </IonItem>;
});

const DimensionLet = observer(
  ({
     className,
     error,
     readonly,
     l,
     w,
     h,
     volume,
     volumeUnit,
     unit,
     mode,
     measurementMode,
     onModeChange,
     onUnitChange,
     onVolumeUnitChange,
     onDimensionChange,
     onVolumeChange,
   }) => {
    const onDimensionSelect = (event: any) => {
      preventDefaultStopProp(event);
      const isAll = observable({ value: false });
      ui.popover({
        event,
        component: () => <UnitMenu
          options={dimensionOptions}
          isAll={isAll.value}
          handler={o => onUnitChange(o, "dimension", isAll.value)}
          isAllHandler={() => isAll.value = !isAll.value}
        />
      });
    };
    // const onVolumeSelect = (event: any) => {
    //   preventDefaultStopProp(event);
    //   if (readonly) return;
    //   return ui.popoverMenu({
    //     event,
    //     menuItems: volumeCBMOptions.map(text => ({
    //       text,
    //       handler: () => onVolumeUnitChange(text)
    //     }))
    //   });
    // };
    const measurementHelp = {
      header: UIText.rfqForm.volume,
      // message: mode === "air" ? UIText.rfqForm.measurementHelpAir : UIText.rfqForm.measurementHelpSea
      message: UIText.rfqForm.measurementHelpAir
    };
    const hasError = (measurementMode === "dimension" && !!error.dimension) ||
      (measurementMode === "volume" && !!error.volume);
    return <IonItem className={`dimensionLet relative ${className || ""}`} lines="full">
      <IonLabel
        position="floating"
        className={`flex ion-align-items-center ${
          // ((mode !== "air" || measurementMode === "dimension")) ? "ion-activatable" : ""
          measurementMode === "dimension" ? "ion-activatable" : ""
        }`}
        color={hasError && "danger"}
      >
        {measurementMode === "dimension" && <span className="flex ion-align-items-center" onClick={onDimensionSelect}>
          {Form.mandatoryFieldLabel(`${readonly ? UIText.rfqForm.dimensionReadonly : UIText.rfqForm.dimension} (${unit || defaultDimensionUnit})`)}&nbsp;
          <IonIcon icon={caretDownSharp} color="dark"/>
        </span>}
        {/*{measurementMode === "volume" && <span className="flex ion-align-items-center" onClick={onVolumeSelect}>*/}
        {/*  {UIText.rfqForm.volume} ({mode === "air" ? "CBM" : volumeUnit})&nbsp;*/}
        {/*  {mode !== "air" && !readonly && <IonIcon icon={caretDownSharp} color="dark"/>}*/}
        {/*</span>}*/}
        {measurementMode === "volume" && <span className="flex ion-align-items-center">
          {Form.mandatoryFieldLabel(`${UIText.rfqForm.volume} (CBM)`)}&nbsp;
        </span>}
        {!readonly && <IonText className="dimensionModeSwitch ion-activatable" color="primary" onClick={onModeChange}>
          {measurementMode === "volume"
            ? UIText.rfqForm.dimensionAltMode(UIText.rfqForm.dimension.toLowerCase())
            : UIText.rfqForm.dimensionAltMode(UIText.rfqForm.volume.toLowerCase())
          }
        </IonText>}
      </IonLabel>
      {measurementMode === "volume" && !readonly && <HelpTip
        className="helpTip absolute"
        help={measurementHelp}
      />}
      {measurementMode === "dimension" && (
        <div className="flex">
          <IonInput
            readonly={readonly}
            name="length"
            inputMode="numeric"
            type="number"
            min="0"
            step="0.01"
            placeholder={UIText.rfqForm.length}
            value={rfqRoundDimension(l, defaultDimensionUnit, unit)}
            // onIonChange={onDimensionChange}
            onIonBlur={onDimensionChange}
          />
          <IonInput
            readonly={readonly}
            name="width"
            inputMode="numeric"
            type="number"
            min="0"
            step="0.01"
            placeholder={UIText.rfqForm.width}
            value={rfqRoundDimension(w, defaultDimensionUnit, unit)}
            // onIonChange={onDimensionChange}
            onIonBlur={onDimensionChange}
          />
          <IonInput
            readonly={readonly}
            name="height"
            inputMode="numeric"
            type="number"
            min="0"
            step="0.01"
            placeholder={UIText.rfqForm.height}
            value={rfqRoundDimension(h, defaultDimensionUnit, unit)}
            // onIonChange={onDimensionChange}
            onIonBlur={onDimensionChange}
          />
        </div>
      )}
      {measurementMode === "volume" && (
        <IonInput
          readonly={readonly}
          name="volume"
          inputMode="numeric"
          type="number"
          min="0"
          step="0.01"
          placeholder="0"
          value={volume}
          onIonChange={onVolumeChange}
        />
      )}
    </IonItem>
  });

const SocLet = observer(
  ({ readonly, value, onChange }) => (
    <IonItem lines="full" className="socLet ion-no-padding">
      <IonLabel position="stacked">
        {UIText.rfqForm.soc}
      </IonLabel>
      <IonCheckboxWithReadonly
        readonly={readonly}
        name="isSOC"
        className="ion-no-margin"
        checked={!!value}
        onIonChange={onChange}
      />
    </IonItem>
  )
);

export const RfqFormFreightItem: React.FC<RfqFormFreightItemProps> = observer(
  ({
     readonly,
     error,
     mode,
     item,
     index,
     shippingUnits,
     onRemoveItem,
     onTypeChange,
     onNotesChange,
     onPiecesChange,
     onWeightChange,
     onUnitChange,
     onContainerChange,
     onDimensionChange,
     onVolumeChange,
     onMeasurementModeChange,
     onVolumeUnitChange,
     onSocChange,
     // onAddInfoChange
   }) => {
    const onTypeChangeSelect = (event: any) => {
      if (mode === "air") return;
      preventDefaultStopProp(event);
      return ui.popoverMenu({
        event,
        menuItems: getFreightItemTypes(mode).map(type => ({
          text: UIText.rfqForm[type],
          handler: () => onTypeChange(type)
        }))
      });
    };

    const LargeFields = <>
      {item.type.match(/pieces|lcl|ltl/g) && (
        <DimensionLet
          className={ui.isMobile ? "fullWidth" : ""}
          error={error}
          readonly={readonly}
          l={item.length}
          w={item.width}
          h={item.height}
          volume={item.volume}
          volumeUnit={item.volumeDispUnit}
          unit={item.dimensionDispUnit}
          mode={mode}
          measurementMode={item.measurementMode}
          onModeChange={onMeasurementModeChange}
          onVolumeUnitChange={onVolumeUnitChange}
          onUnitChange={onUnitChange}
          onDimensionChange={onDimensionChange}
          onVolumeChange={onVolumeChange}
        />
      )}
      {item.type.match(/fcl|ftl/g) && (
        <ContainerLet
          className={ui.isMobile && item.type !== "fcl" ? "fullWidth" : ""}
          hasError={!!error.containerType}
          readonly={readonly}
          type={item.type}
          value={item.containerType}
          units={shippingUnits}
          onChange={onContainerChange}
        />
      )}
      {item.type === "fcl" && (
        <SocLet
          readonly={readonly}
          value={item.isSOC}
          onChange={onSocChange}
        />
      )}
    </>;

    return <div className={`flex piecesReorder ${readonly ? "readonly" : ""}`}>
      {!readonly && <IonReorder
        style={{ background: getIonStyleVal("--ion-color-light-tint") }}
        className="flex ion-align-items-center ion-justify-content-center ion-align-self-stretch ion-no-margin"
      >
        <MdIcon icon={mdiDragVertical} color="dark" />
      </IonReorder>}
      <div className="flex column pieces">
        <IonItemDivider color="primary">
          <IonText
            className={`flex ion-align-items-center ${mode !== "air" ? "ion-activatable" : ""}`}
            onClick={onTypeChangeSelect}
          >
            {index + 1}.&nbsp;{UIText.rfqForm[item.type]}&nbsp;
            {mode !== "air" && !readonly && <IonIcon icon={caretDownSharp} />}
          </IonText>
          {!readonly && <IonButton
            fill="clear"
            slot="end"
            className="ion-no-margin max"
            onClick={onRemoveItem}
          >
            <IonIcon color="light" icon={closeSharp} className="font-l" />
          </IonButton>}
        </IonItemDivider>
        <NotesLet
          hasError={!!error.notes}
          readonly={readonly}
          value={item.notes}
          onChange={onNotesChange}
        />
        <div className="half">
          <PiecesLet
            hasError={!!error.quantity}
            readonly={readonly}
            type={item.type}
            pieces={item.quantity}
            onPiecesChange={onPiecesChange}
          />
          <WeightLet
            hasError={!!error.weight}
            readonly={readonly}
            weight={item.weight}
            unit={item.weightDispUnit}
            // perPiece={mode === "air"}
            onUnitChange={onUnitChange}
            onWeightChange={onWeightChange}
          />
          {!ui.isMobile && <div className="half large">{LargeFields}</div>}
        </div>
        {ui.isMobile && <div className="half">{LargeFields}</div>}

        {/*<IonItem className={`additionalInformation`} lines="full">*/}
        {/*  <IonLabel position="floating">*/}
        {/*    {UIText.rfqForm.additionalInformation}*/}
        {/*  </IonLabel>*/}
        {/*  <TwoStageInput*/}
        {/*    readonly={readonly}*/}
        {/*    name="additionalInformation"*/}
        {/*    heading={UIText.rfqForm.additionalInformation}*/}
        {/*    value={item.additionalInformation}*/}
        {/*    onIonChange={onAddInfoChange}*/}
        {/*    endIcon={ellipsisHorizontal}*/}
        {/*  />*/}
        {/*</IonItem>*/}
      </div>
    </div>
  });