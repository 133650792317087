import { computed, IObservableArray, observable, when } from "mobx";
import { ShippingPlan } from "../../lib/types/rateTypes";
import { isEmpty } from "../../../../utils/helpers";
import { api } from "../../../../client/api";
import { endpointConfig } from "../../../../config/api";
import RateDataViewController from "../../components/RateDataView/controller";
import { AxiosResponse } from "axios";

export interface ComplexShippingRateStore {}

export class ComplexShippingRateController extends RateDataViewController<ComplexShippingRateStore> {
  @observable id: ShippingPlan["id"];
  @observable ready: boolean;

  @computed get plan(): ShippingPlan {
    return (!isEmpty(this.plans) && this.plans[0]) || {} as ShippingPlan;
  };

  constructor() {
    super("ComplexShippingRateController");
    super.setGetPlansMethod(this.getPlan);
  }

  setId = async (id: ShippingPlan["id"]) => {
    if (!id) return;
    this.id = id;
    if (isEmpty(this.group)) return;
    return super.loadAllData()
    .then(() => when(() => !isEmpty(this.plan), { timeout: 10000 }))
    .then(() => this.ready = true);
  };

  cleanup() {
    this.plans = [] as IObservableArray<ShippingPlan>;
    return super.cleanup();
  }

  getPlan = async () => api.GET(endpointConfig.shipping_plan_by_id(this.id))
  .then(response => this.plans = [response.data || {} as ShippingPlan] as IObservableArray<ShippingPlan>);

  getSimplePlans = async () => api.GET(endpointConfig.shipping_plans("simple"))
  .then((response: AxiosResponse<ShippingPlan[]>) => response.data || []);
}