import { observer } from "mobx-react";
import React from "react";
import { SortDirection } from "@material-ui/core";
import { IonButton, IonButtons, IonIcon, IonText } from "@ionic/react";
import { arrowDown, arrowUp, chevronDown, filterOutline, funnel, funnelOutline } from "ionicons/icons";
import { UIText } from "../../client/lang";
import { ui } from "../../client/ui";
import { isEmpty, isNonZeroFalse } from "../../utils/helpers";
import FilterItemList from "../FilterItemList";
import "./styles.less";

export interface ListControlFilterBarProps extends React.HTMLProps<HTMLDivElement> {
  sorts: { name: string, placeholder: string }[];
  sortDir: SortDirection;
  sortBy: string;
  filters?: { name: string, placeholder: string }[];
  selectedFilters?: string[];
  count?: number;
  popoverCssClass?: string;
  onSortByChange?: (sortBy: string) => void;
  onSortDirChange?: (event: any) => void;
  onFilterSelect?: (filter: string) => void;
  onFilterClear?: (event: any) => void;
}

@observer
class ListControlFilterBar extends React.Component<ListControlFilterBarProps> {
  getSortName = sort => (this.props.sorts.find(s => s.name === sort) || {}).placeholder;

  showSortList = (event: any) => ui.popoverMenu({
    event,
    menuItems: this.props.sorts.map((sort, i) => ({
      key: sort.name,
      text: this.getSortName(sort.name),
      color: this.props.sortBy === sort.name ? "primary" : undefined,
      handler: () => this.props.onSortByChange(sort.name)
    }))
  });

  showFilterList = (event: any) => ui.popover({
    event,
    component: () => <FilterItemList
      filters={this.props.filters}
      selectedFilters={this.props.selectedFilters}
      onFilterClear={this.props.onFilterClear}
      onFilterSelect={this.props.onFilterSelect}
    />,
    cssClass: this.props.popoverCssClass,
    showBackdrop: false
  });

  render() {
    const { className, sortDir, sortBy, onSortDirChange, filters, count, selectedFilters } = this.props;
    const sort = this.getSortName(sortBy);

    return <div className={`flex listControlFilterBar ${className || ""}`}>
      <IonButtons style={{ flex: 1 }} className={`flex ion-wrap ${ui.isMobile ? "ion-justify-content-center" : ""}`}>
        <IonButton onClick={this.showSortList} color="light" className="textNoTransform">
          <IonIcon icon={filterOutline} slot="start" />
          <IonText slot="start">{UIText.sortBy}:&nbsp;<span className="textBold">{sort}</span></IonText>
          <IonIcon icon={chevronDown} slot="end" />
        </IonButton>
        <IonButton onClick={onSortDirChange} color="light" className="textNoTransform">
          <IonText className="textBold">{UIText.orderBy}</IonText>
          <IonIcon icon={sortDir === "desc" ? arrowDown : arrowUp} slot="end" />
        </IonButton>
        {!isNonZeroFalse(count) && <IonButton color="light" className="noEvents textNoTransform">
          <IonText className="textBold">Showing:&nbsp;{count}</IonText>
        </IonButton>}
        {!isEmpty(filters) && (
          <div style={{ flex: ui.isMobile ? "unset" : 1 }} className="flex ion-justify-content-end">
            <IonButton onClick={this.showFilterList} color="light" className="textNoTransform">
              <IonIcon icon={isEmpty(selectedFilters) ? funnelOutline : funnel} slot="start" />
              <IonText className="textBold">{UIText.filters}</IonText>
            </IonButton>
          </div>
        )}
      </IonButtons>
    </div>
  }
}

export default ListControlFilterBar;